import * as React from "react";
import * as styles from "./OffsetSolid.module.scss";
import LinkArrow from "./Icons/LinkArrow";
import useWindowSize from "../hooks/window-size";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Link } from "gatsby";
import { Parallax } from "react-scroll-parallax";
import {getPageUrl} from "../contentful/data-provider";

const OffsetSolid = ({ title, body, page, isLink, isTop, isBottom }) => {
	const windowSize = useWindowSize();
	const isNotMobileValue = windowSize.width >= 769 ? -3 : 0;

	return (
		<>
			<div className={[styles.container, isTop ? styles.containerTop : "", isBottom ? styles.containerBottom : ""].join(" ")}>
				<Parallax translateX={[isNotMobileValue, 0]}>
					<div className={styles.inner}>
						{isLink && (
							<div className={styles.arrowContainer}>
								<Link to={getPageUrl(page)}>
									<LinkArrow size={windowSize.width >= 769 ? "large" : "small"} />
								</Link>
							</div>
						)}
						<h2 className={styles.title}>{title}</h2>
						<div className={styles.description}>{renderRichText(body)}</div>
					</div>
				</Parallax>
			</div>
		</>
	);
};

export default OffsetSolid;
