import * as React from 'react';
import {graphql} from 'gatsby';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import PageTitle from '../../components/PageTitle';
import PageHeader from '../../components/PageHeader';
import OffsetSolid from '../../components/OffsetSolid';
import {getImage} from "../../contentful/data-provider";
import FullWidthImage from '../../components/FullWidthImage';
import IconInfoPointsList from '../../components/IconInfoPointsList';

const FoodProcessingPage = ({data}) => {
    const {contentfulFoilProcessingSection} = data;
    return (
        <Layout>
            <main>
                <PageHeader>
                    <PageTitle>
                        <div>{contentfulFoilProcessingSection.subtitle}</div>
                        <h1>{contentfulFoilProcessingSection.title}</h1>
                    </PageTitle>
                </PageHeader>
                <Section isFoilProcessingImageSection>
                    <FullWidthImage
                      isFoilProcessingSection
                      src={getImage(contentfulFoilProcessingSection.image).url}
                      textBlock={
                          <OffsetSolid
                              title={contentfulFoilProcessingSection.imageTitle}
                              body={contentfulFoilProcessingSection.imageBody} isBottom
                          />
                      }
                    />
                </Section>
                <Section hasMaxWidth isFoilProcessingListSection>
                    <IconInfoPointsList elements={contentfulFoilProcessingSection.elements}/>
                </Section>
            </main>
        </Layout>
    )
}

export default FoodProcessingPage;

export function Head({data}) {
    const {contentfulFoilProcessingSection} = data;
    return (
        <title>{contentfulFoilProcessingSection.title} | Derschlag</title>
    )
}

export const query = graphql`
  query ($locale: String!) {
    contentfulFoilProcessingSection(contentful_id: {eq: "5j4HW0n6G6MWXYpFIy9w4i"}, node_locale: {eq: $locale}) {
      id
      title
      subtitle
      image {
        ... ImageFields
      }
      imageBody {
        raw
      }
      imageTitle
      elements {
        id
        title
        body {
          raw
        }
        illustration {
          ... ImageFields
        }
      }
    }
  }
`;