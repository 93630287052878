import * as React from 'react';
import * as styles from './IconInfoPointsList.module.scss';
import IconInfoPoints from './IconInfoPoints';

const IconInfoPointsListGrid = ({elements}) => {
    const renderItems = () => {
        return elements.map((item, index) => {
            return <IconInfoPoints key={index} data={item}/>
        });
    };

    return (
        <div className={styles.container}>
            <ul className={styles.list}>
                {renderItems()}
            </ul>
        </div>
    )
};

export default IconInfoPointsListGrid;