import React from "react";

const LinkArrow = (props) => {
	return (
		<>
			{props.size === "small" && (
				<svg className="link-arrow" width='40' height='40' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'>
					<g fill='none' fill-rule='evenodd'>
						<rect fill='#0E4C8B' width='40' height='40' rx='20' />
						<g id="arrow" stroke='#FFF' stroke-width='1.5'>
							<path stroke-linejoin='round' d='m24.814 15.187-9.627 9.627' />
							<path d='m24.394 25.028.42-9.841-9.841.42' />
						</g>
					</g>
				</svg>
			)}

			{props.size === "large" && (
				<svg className="link-arrow" xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60'>
					<defs>
						<path id='path-1' d='M0 0H550V400H0z'></path>
					</defs>
					<g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
						<g transform='translate(-912 -660)'>
							<g transform='translate(332 360)'>
								<g transform='translate(540)'>
									<mask fill='#fff'>
										<use xlinkHref='#path-1'></use>
									</mask>
								</g>
								<g transform='translate(580 300)'>
									<rect width='60' height='60' x='0' y='0' fill='#0E4C8B' rx='30'></rect>
									<g id="arrow" stroke='#FFF' strokeWidth='1.5' transform='translate(15.078 15.4)'>
										<g transform='rotate(45 7.38 17.819)'>
											<path strokeLinejoin='round' d='M17.658 2.991L3.218 17.431' transform='rotate(-45 10.438 10.211)'></path>
											<path
												d='M17.1879481 17.8184258L17.8190681 3.05753481 3.05689249 3.6873702'
												transform='rotate(-45 10.438 10.438)'
											></path>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)}
		</>
	);
};

export default LinkArrow;
