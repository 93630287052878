import * as React from 'react';
import {getImage} from "../contentful/data-provider";
import * as styles from './IconInfoPoints.module.scss';
import {renderRichText} from "gatsby-source-contentful/rich-text";

const IconInfoPoints = ({data}) => {
    return (
        <li className={styles.container}>
            <div className={styles.iconContainer}>
                <img src={getImage(data.illustration).url} alt={getImage(data.illustration).title}/>
            </div>
            <h2 className={styles.title}>{data.title}</h2>
            {renderRichText(data.body)}
        </li>
    )
};

export default IconInfoPoints;
